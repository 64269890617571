import { Component, Vue, Prop, VModel, Watch } from "vue-property-decorator";
import { PhoneNumber } from "@/types/phoneNumber";

enum FormMode {
  PHONE_NUMBER = "phoneNumber",
  VERIFY_CODE = "confirmationCode",
}

@Component({
  components: {
    PhoneNumberForm: () =>
      import("./components/PhoneNumberForm/PhoneNumberForm.vue"),
    VerifyCodeForm: () =>
      import("./components/VerifyCodeForm/VerifyCodeForm.vue"),
  },
})
export default class PhoneNumberDialog extends Vue {
  @Prop({ type: Object }) private readonly phoneNumber?: PhoneNumber;
  @VModel({ type: Boolean, default: false }) private showed!: boolean;

  private formMode = FormMode.PHONE_NUMBER;

  private get isShowPhoneNumberForm() {
    return this.formMode === FormMode.PHONE_NUMBER;
  }

  private get isShowVerifyCodeForm() {
    return this.formMode === FormMode.VERIFY_CODE;
  }

  @Watch("showed")
  private onChangeShow() {
    if (!this.showed) {
      this.goToPhoneNumberForm();
    }
  }

  private goToPhoneNumberForm() {
    this.formMode = FormMode.PHONE_NUMBER;
  }

  private goToVerifyCodeForm() {
    this.formMode = FormMode.PHONE_NUMBER;
  }

  private onUpdatedPhoneNumber() {
    this.formMode = FormMode.VERIFY_CODE;
  }

  private onVerifiedCode() {
    this.showed = false;
    this.$emit("updated:phone-number");
  }
}
